import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ListTable } from "../../custom-components/ListTable";
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  useTheme,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "react-query";
import { WarningModal } from "../../custom-components/WarningModal";
import { toast } from "react-toastify";
import moment from "moment";
import {
  fetchVideosForAdmin,
  deleteVideo,
  fetchVideosByRole,
} from "../../APIS/videos";
import { VideoDetailModal } from "./videoDetailModal";
import { motion } from "framer-motion";
import { truncateString } from "../../custom-components/TruncateString";
import { getPermissions, getRole } from "../../layout/Sidebar";
import { getVideoStatus } from "../../APIS/roleAndPermission";

const MotionContainer = motion(Container);
const MotionTypography = motion(Typography);
const MotionButton = motion(Button);

export const Videos = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const role = getRole();
  const permissions = getPermissions()?.map((permission) => permission.name);

  const [warningModal, setWarningModal] = React.useState(false);
  const [detailsModal, setDetailsModal] = React.useState(false);
  const [videoId, setVideoId] = React.useState("");
  const [video, setVideo] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredVideos, setFilteredVideos] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState("");

  const isSuperAdmin = role?.name === "SuperAdmin";

  const {
    data: videoStatuses,
    videoStatusLoading,
    videoStatusError,
  } = useQuery("VideoStatus", getVideoStatus);

  const {
    data: videos,
    isLoading,
    refetch,
  } = useQuery(
    ["videos", currentPage + 1, rowsPerPage, searchQuery, statusFilter],
    () => {
      const params = {
        page: currentPage + 1,
        limit: rowsPerPage,
        searchQuery,
        statusFilter,
      };
      return isSuperAdmin
        ? fetchVideosForAdmin(params)
        : fetchVideosByRole(params);
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: ({ videos: fetchedVideos }) => {
        setFilteredVideos(fetchedVideos);
      },
    }
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleWarningModal = (id) => {
    setWarningModal(true);
    setVideoId(id);
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
  };

  const handleCloseDetailsModal = () => {
    setDetailsModal(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleDelete = async () => {
    toast.loading("Deleting video...");
    try {
      await deleteVideo(videoId);
      toast.dismiss();
      toast.success("Video has been successfully deleted");
      handleCloseWarningModal();
      refetch();
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting video: " + error.message);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/edit/${id}`);
  };

  const handleView = (id) => {
    const fullVideoObject = videos?.videos?.find((video) => video?.id === id);
    setVideo(fullVideoObject.id);
    setDetailsModal(true);
  };

  // const normalizeText = (text) => text.toLowerCase().replace(/[-\s]+/g, "-");

  // React.useEffect(() => {
  //   let filtered = videos?.videos || [];

  //   // Filter by search query
  //   if (searchQuery.trim() !== "") {
  //     filtered = filtered.filter((video) => {
  //       const titleMatch = normalizeText(video?.name).includes(
  //         normalizeText(searchQuery)
  //       );
  //       const categoryMatch = normalizeText(video?.category).includes(
  //         normalizeText(searchQuery)
  //       );

  //       return titleMatch || categoryMatch;
  //     });
  //   }

  //   // Filter by status
  //   if (statusFilter) {
  //     let statusValue;
  //     const statusObject = videoStatuses.find(
  //       (status) => status[statusFilter] !== undefined
  //     );
  //     if (statusObject) {
  //       statusValue = statusObject[statusFilter]; // Get the corresponding value
  //     }

  //     if (statusValue !== undefined) {
  //       filtered = filtered.filter(
  //         (video) => video.videoStatusesResponse[0]?.status === statusValue
  //       );
  //     }
  //   }

  //   setFilteredVideos(filtered);
  // }, [searchQuery, statusFilter, videos]);

  const getLatestVideoStatus = (video) => {
    if (video.videoStatusesResponse && video.videoStatusesResponse.length > 0) {
      const latestStatus = video.videoStatusesResponse.sort(
        (a, b) => new Date(b.createdat) - new Date(a.createdat)
      )[0];

      return latestStatus;
    }
    return null;
  };

  const userDataArray = videos?.videos?.map((record) => {
    const latestStatus = getLatestVideoStatus(record);
    let statusMessage = "-";

    if (latestStatus) {
      if (
        latestStatus.statusDescription === "Approved" &&
        latestStatus?.user?.role === "Student" && role?.name =="Teacher"
      ) {
        statusMessage = "Pending";
      } else if (
        latestStatus.statusDescription === "Approved" &&
        latestStatus?.user?.role === "Teacher" 
      ) {
        statusMessage = "Approved";
      } else if (
        latestStatus.statusDescription === "Pending" &&
        latestStatus?.user?.name === "Student"
      ) {
        statusMessage = "Pending";
      } else if (
        latestStatus.statusDescription === "Approved" &&
        latestStatus?.user?.name === "Student" && !role?.name =="Teacher"
      ) {
        statusMessage = "Approved";
      } else if (latestStatus.statusDescription === "Rejected" &&  latestStatus?.user?.role === "Teacher") {
          statusMessage = "Rejected by Teacher";
      } else if(latestStatus.statusDescription === "Rejected" && latestStatus?.user?.role?.name === "Student"){
          statusMessage = "Rejected by Student";
      }else if(latestStatus.statusDescription === "Approved" &&  latestStatus?.user?.role === "SuperAdmin"){
        statusMessage = "Approved By Admin";
      }else{
        statusMessage = latestStatus?.statusDescription;
      }
    }

    return [
      { title: "Id", value: record?.id || "-" },
      { title: "Name", value: truncateString(record?.name, 40) || "-" },
      { title: "Tag", value: record?.category || "-" },
      { title: "Status", value: statusMessage },
      ...(role?.name !== "Student" && role?.name !== "Teacher"
        ? [
            {
              title: "Uploaded By",
              value: record?.uploadedbyUser
                ? `${record?.uploadedbyUser?.firstName || ""} ${
                    record?.uploadedbyUser?.lastName || ""
                  }`.trim() || "-"
                : "-",
            },
            {
              title: "Uploader Email",
              value: record?.uploadedbyUser?.email || "-",
            },
            {
              title: "Approved By",
              value: record?.approvedbyUser
                ? `${record?.approvedbyUser?.firstName || ""} ${
                    record?.approvedbyUser?.lastName || ""
                  }`.trim() || "-"
                : "-",
            },
            {
              title: "Approver Email",
              value: record?.approvedbyUser?.email || "-",
            },
          ]
        : []),
      {
        title: "Uploaded at",
        value: record?.createdat
          ? moment(record.createdat).format("MMM Do YY")
          : "-",
      },
    ];
  });

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      maxWidth="xl"
    >
      <Box
        sx={{
          py: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          background: `linear-gradient(45deg, ${theme.palette.success.light} 30%, ${theme.palette.success.main} 90%)`,
          borderRadius: "15px",
          padding: "20px",
          marginBottom: "20px",
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        }}
      >
        <MotionTypography
          variant="h3"
          fontWeight={700}
          initial={{ x: -50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            color: theme.palette.common.white,
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          All Videos
        </MotionTypography>
        <Box>
          <TextField
            variant="outlined"
            placeholder="Search by title or tag"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: "40px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "40px",
                "& fieldset": {
                  borderColor: theme.palette.grey[400],
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.success.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.success.main,
                },
              },
              "& .MuiInputBase-input": {
                color: "#000",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Dropdown for filtering by status */}
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          displayEmpty
          sx={{
            marginLeft: 2,
            backgroundColor: theme.palette.common.white,
            borderRadius: "40px",
            color: "black",
            "& .MuiSelect-select": {
              padding: "10px 20px",
            },
          }}
        >
          <MenuItem value="">All Statuses</MenuItem>
          <MenuItem value="Pending">Pending Videos</MenuItem>
          <MenuItem value="Approved">Approved Videos</MenuItem>
          <MenuItem value="Rejected">Rejected Videos</MenuItem>
        </Select>

        {!role?.name === "Student" && !role?.name === "Teacher" && (
          <MotionButton
            variant="contained"
            initial={{ x: 50 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 120 }}
            onClick={() => navigate("create")}
            sx={{
              borderRadius: "50px",
              color: theme.palette.common.white,
              backgroundColor: theme.palette.success.dark,
              textTransform: "none",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.success.main,
                boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
              },
            }}
          >
            Add New Video
          </MotionButton>
        )}
      </Box>

      <ListTable
        data={userDataArray}
        error="No Videos Found"
        isLoading={isLoading}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        totalItems={videos?.pagination?.totalRecords || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onEdit={(record) => handleEdit(record[0].value)}
        onDelete={(record) => handleWarningModal(record[0].value)}
        onView={(record) => handleView(record[0].value)}
        permissions={{
          canEdit:
            (permissions?.includes("video_editing") &&
              !role?.name === "Student") ||
            !role?.name === "Teacher",
          canDelete:
            permissions?.includes("video_delete") &&
            role?.name === "SuperAdmin",
          canView:
            role?.name === "admin" ||
            role?.name === "Student" ||
            role?.name === "Teacher" ||
            role?.name === "DataEntry" ||
            role?.name === "SuperAdmin",
        }}
      />
      <WarningModal
        content="Are you sure you want to delete this video?"
        openModal={warningModal}
        handleClose={handleCloseWarningModal}
        handleDelete={handleDelete}
      />
      <VideoDetailModal
        videoId={video}
        openModal={detailsModal}
        handleClose={handleCloseDetailsModal}
        refetch={refetch}
      />
    </MotionContainer>
  );
};
