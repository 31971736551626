import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ListTable } from "../../custom-components/ListTable";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { deleteUser, getAllUsers } from "../../APIS/users";
import { useQuery } from "react-query";
import { WarningModal } from "../../custom-components/WarningModal";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { getPermissions, getRole } from "../../layout/Sidebar";

export const MotionContainer = motion(Container);
export const MotionTypography = motion(Typography);
export const MotionButton = motion(Button);

export const Users = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const role = getRole();
  const permissions = getPermissions()?.map((permission) => permission?.name);

  const [warningModal, setWarningModal] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState("");

  const { data: users, isLoading, refetch } = useQuery("users", getAllUsers);

  const filteredUsers = users?.filter((user) =>
    `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const totalRecords = filteredUsers?.length || 0;
  const paginatedUsers = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || [];


  const handleCreateUser = () => {
    navigate("create");
  };

  const handleWarningModal = (id) => {
    setWarningModal(true);
    setUserId(id);
  };

  const handleCloseWarningModal = () => {
    setWarningModal(false);
  };

  const handleDelete = async () => {
    toast.loading("Deleting user...");
    try {
      await deleteUser(userId);
      toast.dismiss();
      handleCloseWarningModal();
      toast.success("User has been successfully deleted");
      refetch();
    } catch (error) {
      toast.dismiss();
      toast.error("Error deleting user: " + error.message);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userDataArray = paginatedUsers?.map((record) => [
    { title: "Id", value: record.id },
    {
      title: "Name",
      value: (
        <Link
          href={`/admin/users/${record.id}`}
          style={{ textDecoration: "none", color: theme.palette.success.main }}
        >
          {record?.firstname} {record?.lastname}
        </Link>
      ),
    },
    { title: "Email", value: record.email },
    { title: "Phone", value: record.phone },
  ]);

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      maxWidth="xl"
    >
      <Box
        sx={{
          py: 4,
          display: "flex",
          justifyContent: "space-between",
          flexDirection:{xs: "column", md: "row"},
          alignItems: "center",
          background: `linear-gradient(45deg, ${theme.palette.success.light} 30%, ${theme.palette.success.main} 90%)`,
          borderRadius: "15px",
          padding: "20px",
          marginBottom: "20px",
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
          gap: 2,
        }}
      >
        <MotionTypography
          variant="h3"
          fontWeight={700}
          initial={{ x: -50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            color: theme.palette.common.white,
            textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
          }}
        >
          All Users
        </MotionTypography>
        <Box>
          <TextField
            variant="outlined"
            placeholder="Search user by name or email"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: "40px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "40px",
                "& fieldset": {
                  borderColor: theme.palette.grey[400],
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.success.main,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.success.main,
                },
              },
              "& .MuiInputBase-input": {
                color: "#000",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.grey[500] }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <MotionButton
          variant="contained"
          initial={{ x: 50 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120 }}
          sx={{
            borderRadius: "50px",
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            textTransform: "none",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: theme.palette.success.main,
              boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
            },
          }}
          onClick={handleCreateUser}
        >
          Create New User
        </MotionButton>
      </Box>
      {/* <ListTable
        data={userDataArray}
        error="No users exist"
        isLoading={isLoading}
        onEdit={(record) => navigate(`edit/${record[0].value}`)}
        onDelete={(record) => handleWarningModal(record[0].value)}
        onView={(record) => navigate(`/admin/users/${record[0].value}`)}
        permissions={{
          canEdit: true,
          canDelete: true,
          canView: true,
        }}
      /> */}
      <ListTable
        data={userDataArray}
        error="No users exist"
        isLoading={isLoading}
        onEdit={(record) => navigate(`edit/${record[0].value}`)}
        onDelete={(record) => handleWarningModal(record[0].value)}
        onView={(record) => navigate(`/admin/users/${record[0].value}`)}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalRecords}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        permissions={{
          canEdit: true,
          canDelete: true,
          canView: true,
        }}
      />
      <WarningModal
        content="Are you sure you want to delete this user?"
        openModal={warningModal}
        handleClose={handleCloseWarningModal}
        handleDelete={handleDelete}
      />
    </MotionContainer>
  );
};
