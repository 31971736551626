import { axiosInstance } from "../../AxiosInstance";

export const createUser = async (data) => {
  try {
    const response = await axiosInstance.post("/users/CreateUser", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    console.log("Error creating user:", error.response);
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get("/users/userslisting");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const getAllStudents = async () => {
  try {
    const response = await axiosInstance.get("/users/fetchStudentsOrTeachers/student");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const getAllTeachers = async () => {
  try {
    const response = await axiosInstance.get("/users/fetchStudentsOrTeachers/teacher");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const getStudentTeacherMapping = async () => {
  try {
    const response = await axiosInstance.get("/users/fetch_student_teacher_Mapping");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const linkStudentsWithTeacher = async (studentId, teacherId) => {
  try {
    const response = await axiosInstance.get(`/users/insert_student_teacher_link/${studentId}/${teacherId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const getSingleUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/users/fetchuser/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

export const updateUser = async (data, id) => {
  try {
    const response = await axiosInstance.put(`/users/update/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    console.log("Error updating user:", error.response);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.delete(`/users/deleteUser/${id}`, {
      headers: {
        Authorization: "Bearer" + localStorage.getItem("authToken"),
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

export const updateUserRole = async (role) => {
  try{
    const response = await axiosInstance.put(`/users/updateRoles`, role, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("authToken"),
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    console.log("Error updating user:", error.response);
  }
}

export const updateUserPermissions = async (permissions) => {
  try{
    const response = await axiosInstance.put(`/users/updatePermissions`, permissions, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem("authToken"),
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    console.log("Error updating user:", error.response);
  }
}


export const getAllPermissions = async () => {
  try {
    const response = await axiosInstance.get("/users/allpermissions");
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};


export const getAllPermissionsByRole = async (id) => {
  try {
    const response = await axiosInstance.get(`/users/allRolePermissions/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};




