import React, { useState } from "react";
import { Box, Typography, TextField } from "@mui/material";

const displayComments = (comments) => {
  return comments?.map((comment) => {
    const { firstname, role } = comment.user;
    return `${firstname} (${role.name}): ${comment.comment}`;
  });
};

const CommentsSection = ({ setComment, comment, formattedComments, theme }) => {
 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        // padding: "10px",
      }}
    >
      {/* Comments Section */}
      <Box
        sx={{
          height: "200px",
          width: "100%",
          overflowY: "auto",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {formattedComments?.map((formattedComment, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              backgroundColor: "#f0f0f0",
              padding: "8px",
              borderRadius: "10px",
              maxWidth: "80%",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              {formattedComment}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Add Comment Section */}
      <TextField
        label="Add a comment (optional)"
        value={comment} // Binding the state to the input field
        onChange={(e) => setComment(e.target.value)} // Updating the state on input change
        fullWidth
        required={true}
        placeholder="Type a comment ..."
        variant="outlined"
        
        sx={{
          marginBottom: "0px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                theme.palette.mode === "dark" ? "#FFD700" : "#006400",
            },
            "&:hover fieldset": {
              borderColor:
                theme.palette.mode === "dark" ? "#FFD700" : "#006400",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                theme.palette.mode === "dark" ? "#FFD700" : "#006400",
            },
          },
        }}
      />
    </Box>
  );
};

export default CommentsSection;
