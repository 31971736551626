// src/components/LinkStudentTeacher.js

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery} from "react-query";
import {
  getAllStudents,
  getAllTeachers,
  linkStudentsWithTeacher,
} from "../../APIS/users";
import { toast } from "react-toastify";

const LinkStudentwithTeacher = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Fetch Students
  const {
    data: studentsData,
    isLoading: isStudentsLoading,
    isError: isStudentsError,
    error: studentsError,
  } = useQuery("students", getAllStudents);

  const {
    data: teachersData,
    isLoading: isTeachersLoading,
    isError: isTeachersError,
    error: teachersError,
  } = useQuery("teachers", getAllTeachers);

  const formik = useFormik({
    initialValues: {
      studentId: "",
      teacherId: "",
    },
    validationSchema: Yup.object({
      studentId: Yup.string().required("Student is required"),
      teacherId: Yup.string().required("Teacher is required"),
    }),
    onSubmit: async(values) => {
      setLoading(true);
      const response = await linkStudentsWithTeacher(
        values.studentId,
        values.teacherId
      );

      if (response.Id) {
        toast.success(
          response?.message || "Student and teacher linked successfully!"
        );
        setLoading(false);

        formik.resetForm();
        navigate('/admin/student-teacher')
      } else {
        setLoading(false);
        toast.error(response?.message || "Failed to Assign student to teacher.");
      }
    },
  });

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Link Student to Teacher
          </Typography>

          {(isStudentsError || isTeachersError) && (
            <Typography color="error" variant="body1">
              {studentsError?.message ||
                teachersError?.message ||
                "Error fetching data."}
            </Typography>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Select Student"
                  name="studentId"
                  value={formik.values.studentId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.studentId && Boolean(formik.errors.studentId)
                  }
                  helperText={
                    formik.touched.studentId && formik.errors.studentId
                  }
                  disabled={isStudentsLoading || isTeachersLoading}
                >
                  {isStudentsLoading ? (
                    <MenuItem value="">
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    studentsData?.map((student) => (
                      <MenuItem key={student.id} value={student.id}>
                        {student?.firstname}{" "}{student?.lastname}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Select Teacher"
                  name="teacherId"
                  value={formik.values.teacherId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.teacherId && Boolean(formik.errors.teacherId)
                  }
                  helperText={
                    formik.touched.teacherId && formik.errors.teacherId
                  }
                  disabled={isStudentsLoading || isTeachersLoading}
                >
                  {isTeachersLoading ? (
                    <MenuItem value="">
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    teachersData?.map((teacher) => (
                      <MenuItem key={teacher?.id} value={teacher?.id}>
                        {teacher?.firstname}{" "}{teacher?.lastname}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>
            </Grid>

            <CardActions
              sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
            >
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/admin/users")}
                sx={{
                  textTransform: "none",
                  mr: 2,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{
                  textTransform: "none",
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Assign"
                )}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LinkStudentwithTeacher;
