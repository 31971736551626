import { axiosInstanceForFile, axiosInstance, axiosInstanceUnauth } from "../../AxiosInstance";
export const fetchVideos = async (page, pageSize, searchQuery) => {

  try {
    const response = await axiosInstanceUnauth.get(`/videos/fetchvideos/${page}/${pageSize}?search=${searchQuery}`);
    return response.data;

  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const fetchVideosByRole= async (params) => {
  try {
    const response = await axiosInstance.get(`/videos/fetchVideosByRole/${params?.page}/${params?.limit}`, {
      params: {
        search: params?.searchQuery, // Query parameter for search
        statusFilter: params?.statusFilter, // Query parameter for status filter
      },
    });

    return response.data;

  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};


export const fetchLatestVideos = async () => {

  try {
    const response = await axiosInstance.get(`videos/fetchlatestVideos`);

    return response.data;

  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const fetchVideosForAdmin = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/videos/supervideolisting/${params?.page}/${params?.limit}`,
      {
        params: {
          search: params?.searchQuery, // Query parameter for search
          statusFilter: params?.statusFilter, // Query parameter for status filter
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
    throw error; // Propagate the error if necessary
  }
};


export const fetchVideosTags = async () => {
  try {
    const response = await axiosInstance.get(`/videos/fetchvideotags`);
    return response.data;
  } catch (error) {
    console.error("Error fetching video tags:", error);
    throw error; // Re-throw the error so React Query can handle it
  }
};

export const createVideoTag = async (tagData) => {
  try {
    const response = await axiosInstance.post(`/videos/createVideoTags`, [tagData]);
    return response.data;
  } catch (error) {
    console.error("Error creating video tag:", error);
    throw error;
  }
};


export const fetchUnauthVideos = async (searchQuery) => {
  try {
    const response = await axiosInstanceUnauth.get(`/videos/fetchvideos?search=${searchQuery}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};


export const fetchVideo = async (id) => {
  try {
    const response = await axiosInstance.get(`/videos/fetchvideo/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};
export const fetchVideoAouth = async (id) => {
  try {
    const response = await axiosInstance.get(`/videos/fetchvideoAuth/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};
export const makeVideoFavorite = async (id, isFavorite) => {
  try {
    const response = await axiosInstance.post(
      "/videos/favoritevideo",
      { videoId: id, isFavorite: isFavorite },
      {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("authToken"),
        },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding video to favorites:", error);
  }
};

export const uploadVideo = async(data) => {
    try{
      const response = await axiosInstanceForFile.post("/videos/upload", data, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("authToken"),
          'Content-Type': 'multipart/form-data',
        },
      });

      return response;
    } catch (error) {
      // Handle errors
      console.log("Error uploading video:", error.response);
    }

  };

  export const updateVideo = async (data, id) => {
    try{
      const response = await axiosInstance.put(`/videos/update/${id}`, data, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("authToken"),
          'Content-Type': 'multipart/form-data',
        },
      });

      return response;
    } catch (error) {
      // Handle errors
      console.log("Error updating video:", error.response);
    }
  }

  export const deleteVideo =async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/videos/deleteVideo/${id}`,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("authToken"),
          },
        }
      );
  
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error deleting video.:", error);
    }
  }

  export const deleteVideoTag = async (id) => {
    try {
      const response = await axiosInstance.delete(`/videos/deleteVideoTag/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting video tag:", error);
      throw error;
    }
  };

  export const updateVideoStatus = async (videoId, isVerified) => {
    try {
      const response = await axiosInstance.put(`/videos/verifyVideo/${videoId}`, {
        isVerified: isVerified
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("authToken"),
        },
      });
  
      console.log('Video status update response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error updating video status:', error);
      throw error;
    }
  };