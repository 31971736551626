import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";

export const SocialMediaIconsModal = ({
  module,
  showSocialMediaModal,
  setShowSocialMediaModal,
}) => {
  const moduleLink = `https://islaminfotube.com/${module?.id}`;
  const shareText = "Check out this module: " + moduleLink;
  const openInstagramWindow = () => {
    window.open("https://www.instagram.com/", "_blank", "width=600, height=400");
  };

  return (
    <Dialog
      open={showSocialMediaModal}
      onClose={() => setShowSocialMediaModal(false)}
    >
      <DialogTitle>Share on Social Media</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setShowSocialMediaModal(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          Share this module on your favorite social media platform.
        </DialogContentText>
        <Box display="flex" justifyContent="space-evenly">
          <TwitterShareButton
            url={moduleLink}
            title={shareText}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton
            url={moduleLink}
            quote={shareText}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <WhatsappShareButton
            url={moduleLink}
            title={shareText}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
