import React from 'react';
import { Typography, Box, Card, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DevicesIcon from '@mui/icons-material/Devices';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import GavelIcon from '@mui/icons-material/Gavel';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const privacyPolicy = [
  {
    key: "Information We Collect",
    value: "We may collect and process the following types of information:",
    list: [
      { title: "Personal Information", description: "When you register on our site or app, we collect your email address and password for account creation.", icon: <AccountCircleIcon /> },
      { title: "Usage Data", description: "We collect information about your activity on the website and app, including the pages/modules visited (e.g., Quran, Ahadees, Dua, Tafseer) and interaction time.", icon: <DataUsageIcon /> },
      { title: "Device Information", description: "Information such as your device type, IP address, browser type, and operating system.", icon: <DevicesIcon /> },
      { title: "Cookies", description: "We may use cookies to enhance your browsing experience. You can manage cookie preferences through your browser settings.", icon: <SettingsIcon /> },
    ],
  },
  {
    key: "How We Use Your Information",
    value: "We use your information to:",
    list: [
      { title: "Provide and personalize services", description: "Ensure smooth user experience and optimize website/app functionality.", icon: <SettingsIcon /> },
      { title: "Improve user experience", description: "Monitor and analyze usage to prevent fraudulent activities.", icon: <SecurityIcon /> },
      { title: "Communication", description: "Send notifications related to your account or app updates (optional).", icon: <ContactMailIcon /> },
    ],
  },
  {
    key: "Sharing Your Information",
    value: "We respect your privacy and do not sell or rent your personal data. However, your information may be shared with:",
    list: [
      { title: "Service Providers", description: "We may work with third-party service providers for website hosting, analytics, or customer support. They are required to comply with strict data protection standards.", icon: <SettingsIcon /> },
      { title: "Legal Authorities", description: "We may disclose your data if required to do so by law, to protect our rights, or comply with any legal obligation.", icon: <GavelIcon /> },
    ],
  },
  {
    key: "Data Security",
    value: "We take reasonable precautions to secure your personal information. This includes using secure encryption techniques and ensuring that access to your personal data is limited to authorized personnel. However, no data transmission over the internet can be guaranteed to be 100% secure.",
    list: [],
  },
  {
    key: "Retention and Deletion of Data",
    value: "We retain your personal information for as long as necessary to provide our services or comply with legal obligations. You may request to have your account and associated data deleted by contacting us.",
    list: [],
  },
  {
    key: "Your Rights",
    value: "You have the following rights regarding your personal information:",
    list: [
      { title: "Access", description: "Request access to your personal data.", icon: <ContactMailIcon /> },
      { title: "Correction", description: "Rectify incorrect or incomplete data.", icon: <AccountCircleIcon /> },
      { title: "Deletion", description: "Request deletion of your data from our systems.", icon: <GavelIcon /> },
      { title: "Opt-out", description: "Opt-out of marketing communications or data tracking.", icon: <SettingsIcon /> },
    ],
  },
  {
    key: "Third-Party Links",
    value: "Our website and app may contain links to third-party websites. We are not responsible for the privacy practices of those websites, and we encourage you to review their privacy policies.",
    list: [],
  },
  {
    key: "Children’s Privacy",
    value: "Islam Info Tube is not intended for children under the age of 13. We do not knowingly collect personal data from children without parental consent. If we become aware of any data collected from children, we will take steps to delete it.",
    list: [],
  },
  {
    key: "Changes to This Privacy Policy",
    value: "We may update this Privacy Policy periodically. Any changes will be reflected on this page, and we will notify you via email or within the app if the changes are significant.",
    list: [],
  },
  {
    key: "Contact Information",
    value: "If you have any questions or concerns about our privacy practices, you can contact us at the following:",
    list: [
      { title: "Email", description: "rahimdadabhoy@gmail.com", icon: <ContactMailIcon /> },
      {title: "Phone",  description:"832-275-7878", icon: <ContactMailIcon />} ,
      { title: "Address", description: "Abdul Rahim dadabhoy, 11311 Mirrlees Path, Richmond TC 77407", icon: <ContactMailIcon /> },
    ],
  },
  {
    key: "Location Data",
    value: "Our app collects location data to provide key features such as accurate Namaz timings and Qibla direction based on your location. Location data may also be used to deliver timely Namaz notifications, even when the app is not actively in use. We always request your explicit consent before collecting location data. The data collected may include your device's foreground or background location to ensure uninterrupted functionality. This information is not shared with third parties, except for trusted service providers supporting the app's features.",
    list: []
  },  
  {
    key: "Last Updated",
    value: `This Privacy Policy was last updated on ${new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}.`,
    list: [],
  },
];

const PrivacyPolicy = () => {
  const primaryColor = '#00b386'; // Green theme
  const secondaryColor = '#fff6a9'; // Yellowish
  const backgroundColor = 'linear-gradient(135deg, #e8f5e9, #e0f7fa)'; // Gradient for background

  return (
    <Box 
      sx={{ 
        p: { xs: 2, md: 4 }, 
        background: backgroundColor, 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography 
        variant="h3" 
        gutterBottom 
        sx={{ 
          fontWeight: 600, 
          color: primaryColor, 
          textAlign: 'center',
          mb: 4
        }}
      >
        Privacy Policy
      </Typography>

      <Typography 
        variant="body1" 
        sx={{ 
          color: primaryColor, 
          mb: 3, 
          textAlign: 'center', 
          maxWidth: '800px', 
          fontSize: '1.2rem',
        }}
      >
        Islam Info Tube ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use the Islam Info mobile application (the "App"). By using the App, you agree to the practices described in this Privacy Policy.
      </Typography>

      {privacyPolicy.map((section, index) => (
        <Card key={index} sx={{ width: '100%', maxWidth: '800px', p: 3, mb: 4, boxShadow: 2, borderRadius: 3 }}>
          <Typography variant="h5" sx={{ color: primaryColor, fontWeight: 600, mb: 2 }}>
            {section.key}
          </Typography>
          <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
            {section.value}
          </Typography>

          <Divider sx={{ mb: 2 }} />

          {section.list && section.list.map((item, idx) => (
            <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{ mr: 2 }}>{item.icon}</Box>
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 500, color: primaryColor }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  {item.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Card>
      ))}
    </Box>
  );
};

export default PrivacyPolicy;
