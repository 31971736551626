import React, { useState } from  "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../context/SearchContext";
export const VideoSearch = () => {
  const { updateSearchQuery } = useSearch();

  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    updateSearchQuery(newValue);
  };
    return(
        <TextField
        size="small"
        fullWidth
        placeholder="Search ..."
        onChange={handleSearchChange}
        value={searchValue}
        sx={{
          marginLeft: "13%",
          width: "60ch",
          boxShadow: "inset 0px 0px 10px rgba(18, 92, 19 0.2)",
          borderRadius: "20px",
          border: "1px solid white"

        }}
        InputProps={{
          style: {
            borderRadius: "20px",
            backgroundColor: "#fff",
            color: "black"
          },
          endAdornment: <SearchIcon sx={{ color: "green", m: "5px" }} />,
        }}
      />
    )
}